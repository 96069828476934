<template>
  <div class="PageBodyWrapper">
    <slot></slot>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.PageBodyWrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
</style>
